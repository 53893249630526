import React from 'react';

const Layout = ({ children }) => {
	return (
		<main>
			<div className="Layout">
				{children}
			</div>
		</main>
	);
}

export default Layout;