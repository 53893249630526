import React, { createContext, useState, useMemo } from 'react';

const UserContext = createContext();

export const UserProvider = React.memo(({ children }) => {
    const [user, setUser] = useState(null);
    const value = useMemo(() => ({ user, setUser }), [user, setUser]);

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
});

export default UserContext;