import React from "react";

import '@styles/loading.scss';


const Loading = React.memo(({isdashPage}) =>{

    return (
        <div className={isdashPage ? "container-loading page": "container-loading"}>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
})

export default Loading;